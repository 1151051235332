<template>
<middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div v-if="variables">
    <b-row class="mt-1">
      <b-col cols="12">
        <div class="d-flex justify-content-between">
          <div></div>
          <b-form-checkbox
            switch
            class="custom-control-primary mb-50"
            v-model="advancedMode"
          >
            Advanced
            <helper-tooltip
              size="15"
              innerText="eopae"
              tooltipPlacement="right"
            />
          </b-form-checkbox>
        </div>
        
      </b-col>
    </b-row>
    <b-row class="mx">
      <b-col md="12" v-if="!advancedMode">
        <div 
          class="border rounded shadow mx-0 mb-2 pt-1 pb-1"
          v-for="key in Object.keys(agentsBySource)"
          :key="agentsBySource[key].id + key"
        >
          <b-row class="mx-0">
            <b-col md="10">
              <custom-input
                :id="`custom-input-source-${key}}`"
                :possibleValues="variables"
                v-model="mainComparison[key]"
                advanced
              >
                <template #label>
                  What'd you to compare?
                  <helper-tooltip
                    size="12"
                    innerHTML="eopae"
                    tooltipPlacement="right"
                  />
                </template>
              </custom-input>
            </b-col>
            <b-col md="2">
              <b-button
                class="mt-2 p-75"
                variant="outline-danger"
                @click="deleteSource(key)"
              >
                <b-icon
                  icon="X"
                  scale="1.3"
                />
              </b-button>

            </b-col>
          </b-row>

          <hr class="w-100">
          <b-row class="mx-0">
            <b-col md="12">
              <custom-input-list
                :key="getID(key) + version"
                :middlewareID="middlewareID"
                :variables="variables"
                title="Agents"
                v-model="comparisonData[key]"
                :defaultAgentValue="defaultValueIPs"
                setRegister="register_2"
                @deleteItem="(index) => { deleteItem(index, key) }"
                @itemAdded="itemAdded"
              />
            </b-col>
          </b-row>
        </div>
        <div class="d-flex justify-content-center mb-2">
          <b-button
            class="p-50"
            variant="outline-success"
            pill
            @click="setupNewList"
          >
            <feather-icon
              icon="PlusIcon"
              size="20"
            />
          </b-button>
        </div>
      </b-col>
      <b-col md="12" v-else>
        <actions-reorder-list
          :key="getID(`actions-reorder-list-${version}`)"
          class="w-100"
          :middlewareID="middlewareID"
          v-model="agents"
          @agentAdded="version++"
          @agentEdited="version++"
          @agentDeleted="version++"
        />
      </b-col>
    </b-row>
  </div>
</middleware-sidebar-outline>
</template>


<script>
import {
  BContainer,
  BSidebar,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BTab,
  BFormSelect,
  BTabs,
  BFormSelectOption,
  BFormCheckbox
} from "bootstrap-vue";
import CustomInput from "../CustomInput.vue";
import DraggableItems from "../DraggableItems.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { v4 as uuidv4 } from "uuid";
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import Drop from "../Drop.vue";
import Methods from "@/custom/class/Enum/Methods.js";
import HelperTooltip from "@/layouts/components/HelperTooltip.vue";
import KeyValue from "../Requester/KeyValueItems.vue";
import { makeToast } from "@/layouts/components/Popups";
import VariablesPanel from "../VariablesPanel.vue";
import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue';
import CustomInputList from '@/layouts/components/Transmission/Middleware/CustomInputList.vue';
import Source from '@/custom/class/Agent/Source.js';
import Sources from '@/custom/class/Enum/Sources.js'
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import Agent from '@/custom/class/Agent/Agent.js'
import Agents from '@/custom/class/Enum/Agents.js'
import VSelect from 'vue-select'
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup.js';
import 'prismjs/components/prism-xml-doc.js';
import 'prismjs/components/prism-json.js';
import 'prismjs/themes/prism-tomorrow.css';
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';
import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"


export default {
  mixins: [MiddlewaresMixin],
  
  components: {
    BContainer,
    BSidebar,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BForm,
    BTab,
    BTabs,
    DraggableItems,
    CustomInput,
    VuePerfectScrollbar,
    BInputGroupAppend,
    BInputGroupPrepend,
    JsonEditor,
    Drop,
    BFormSelect,
    HelperTooltip,
    BFormSelectOption,
    KeyValue,
    VariablesPanel,
    PrismEditor,
    BFormCheckbox,
    ActionsReorderList,
    CustomInputList,
    VSelect,
    MiddlewareSidebarOutline,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    middlewareID: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    i18nKey: {
      type: String,
      required: true,
    },
    middlewareType:{
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      defaultAgentActionID: 3,
      defaultAgentBlockID: 1,
      defaultSource: 7,
      variables: undefined,
      variant_map: undefined,
      uuidMap: {},
      comparisonData: {},
      agentList: [],

      scriptList: undefined,
      scriptAgentsList: [],
      source: '',

      agentsBySource: {},
      mainComparison: {},
      version: 0,
      changerSelection: undefined,
      selectChangerOptions: [],
      changerConfig: undefined,
      previousKey: undefined,

      sourceList: {},

      changedFlag: false,
      advancedMode: false,
    };
  },
  computed: {
    agents: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    sourceOptions() {
      return new Sources().items
    },
    agentOptions() {
      return new Agents().items
    },
    defaultValueIPs() {
      return DefaultAgent.setAgent(this, {
        id: null,
        enum_agent_action_id: 3,
        enum_agent_block_id: 1,
        register_1: {
          value: '',
          source: 7,
        },
        register_2: {
          source: 7,
          value: ''
        },
        register_destiny: {
          source: null,
          value: null,
        },
        execution_order: 1,
        fatal_on_fail: true,
      })
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initializeAgents();
    },
    configureVars() {
      this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
      this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
    },
    isObject(item) {
      return item instanceof Object && !(item instanceof Array);
    },
    isArray(item) {
      return item instanceof Array;
    },
    initializeAgents() {
      if (this.agents.length) {

        this.agents.forEach((value) => {
          if (!this.agentsBySource[this.getSourceLabel(value.register_1.source)]) this.agentsBySource[this.getSourceLabel(value.register_1.source)] = []

          this.agentsBySource[this.getSourceLabel(value.register_1.source)].push(value)
        })
      } else {
        let source = this.getSourceLabel(this.defaultSource)
        this.pushNewList(source, this.defaultSource)
      }
      this.setupList()
    },
    setupList() {
      Object.keys(this.agentsBySource).forEach((value) => {
      
        this.sourceList[value] = this.agentsBySource[value][0]

        this.comparisonData[value] = MiddlewareFunctions.getValueFrom(
          {
            default: [],
            source: this.agentsBySource[value][0].register_2.source,
            from: {
              source: "register_2",
            },
            modifier: false,
            multiple: true,
          },
          this.agentsBySource[value]
        );
        
        this.$set(this.mainComparison, value, { source: this.agentsBySource[value][0].register_1.source, value: this.comparisonData[value][0].register_1.value })
      }) 
    },
    pushNewList(source, sourceID) {
      this.$set(this.agentsBySource, source, [])
      this.agentsBySource[source].push(
        DefaultAgent.setAgent(this, {
          id: null,
          enum_agent_action_id: this.defaultAgentActionID,
          enum_agent_block_id: this.defaultAgentBlockID,
          register_1: {
            source: sourceID,
          },
          register_2: {
            source: 7,
          },
          register_destiny: {
            source: null,
            value: null,
          },
          execution_order: 1,
          fatal_on_fail: true,
        })
      )
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    buildAgents() {
      this.agentList = [];

      Object.keys(this.agentsBySource).forEach((value) => {
        this.comparisonData[value].forEach((item) => {
          this.agentList.push(this.defineList(value, item));
        })
      })
      
      this.saveAgents();
    },
    saveAgents() {
      this.$store
        .dispatch("saveAgents", {
          agentList: this.agentList,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          makeToast({
            title: this.$t("agent.toast.create_agents.success.title"),
            text: this.$t("agent.toast.create_agents.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });
          this.$emit("saved", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    defineList(key, agent) {
      return {
        agent_id: agent.id || null,
        middleware: this.middlewareID,
        enum_agent_action: agent.enum_agent_action.id,
        enum_agent_block_id: agent.enum_agent_block.id,
        enum_source_destiny_id: agent.register_destiny.source || null,
        destiny_value: agent.register_destiny.value || null,
        enum_source_register_1: this.mainComparison[key].source || this.defaultSource,
        register_1_value: this.mainComparison[key].value || null,
        enum_source_register_2: parseInt(agent.register_2.source) || null,
        register_2_value: agent.register_2.value || null,
        fatal_on_fail: agent.fatal_on_fail,
      };
    },
    sidebarShown() {
this.$emit("shown")
      let el = document.getElementsByTagName("html");
      el[0].classList.add("hide-scrollbar");
    },
    sidebarHidden() {
      this.$emit("close");

      let el = document.getElementsByTagName("html");
      el[0].classList.remove("hide-scrollbar");
    },
    deleteItem(index, key) {
      if (this.comparisonData[key][index].id) {
        this.$store
          .dispatch("deleteAgent", {
            id: this.comparisonData[key][index].id,
            transmissionID: this.$route.params.transmissionID,
          })
          .then(() => {
            makeToast({
              title: this.$t("agent.toast.delete.success.title"),
              text: this.$t("agent.toast.delete.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.comparisonData[key].splice(index, 1);
            this.version++;
          })
          .catch(() => {
            makeToast({
              title: this.$t("agent.toast.delete.error.title"),
              text: this.$t("agent.toast.delete.error.message"),
              variant: "danger",
              icon: "XIcon",
            });
            this.version++;
          });
        } else {
            this.comparisonData[key].splice(index, 1);
            this.version++;
        }
    },
    itemAdded() {
      this.version++;
    },
    deleteSource(key) {
      delete this.agentsBySource[key]
      delete this.sourceList[key]
      
      this.version++;
    },
    getSourceLabel(id) {
      const source = new Source(id);
      return this.$t(source.label);
    },
    getSource(id) {
      return new Source(id);
    },
    getAgentEnumByID(id) {
      return new Agent(id);
    },
    setupAgentChanger(payload, key) {
      this.openChanger(
        {
          agentOptions: this.agentOptions,
          changerConfig: { type: 'agent', key, index: payload.index },
          changerSelection: this.getAgentEnumByID(payload.item.item.id),
          changerUID: this.getID('changer'),
          previousKey: key
        }
      )
    }, 
    setupNewList() {
      let agentOptions = this.sourceOptions.filter((el) => !this.includesSourceList(this.$t(el.label)))
      console.log(agentOptions)
      this.openChanger(
        {
          agentOptions,
          changerConfig: { type: 'new_list'},
          changerSelection: agentOptions[0],
          changerUID: this.getID('changer')
        }
      )
    },
    includesSourceList(label) {
      return Object.keys(this.sourceList).includes(label)
    },
    buildChangerOptions(key) {
      let changerOptions = [];
      this.comparisonData[key].forEach((agent) => {
        changerOptions.push({ label: 'Agent', item: agent.enum_agent_action, value: this.$t(agent.enum_agent_action.label) });
      })

      return changerOptions;
    },
    changerSubmit() {
      let key = this.changerConfig.key;
      switch(this.changerConfig.type) {
        case 'agent': {
          let index = this.changerConfig.index;
          let j = JSON.parse(JSON.stringify(this.comparisonData[key][[index]]));
          j.enum_agent_action = this.changerSelection;

          Object.keys(this.comparisonData[key][index]['enum_agent_action']).forEach((field) => {
            this.$set(this.comparisonData[key][index]['enum_agent_action'], field, this.changerSelection[field]);
          });

          this.version++
        } break;
        case 'source': {
          this.agentsBySource[this.$t(this.changerSelection.label)] = this.agentsBySource[this.previousKey]
          this.sourceList[this.$t(this.changerSelection.label)] = this.sourceList[this.previousKey] 
          this.comparisonData[this.$t(this.changerSelection.label)] = this.comparisonData[this.previousKey]
          this.mainComparison[this.$t(this.changerSelection.label)] = { source: this.changerSelection.id, value: this.mainComparison[this.previousKey].value }
          
          delete this.sourceList[this.previousKey]
          delete this.agentsBySource[this.previousKey]
          delete this.comparisonData[this.previousKey]
          delete this.mainComparison[this.previousKey]

          this.version++
        } break;
        case 'new_list': {
          let sourceLabel = this.$t(this.changerSelection.label)

          this.pushNewList(sourceLabel, this.changerSelection.id)
          
          this.setupList()

          this.version++
        } break;
      }
    },
    resetChanger() {
      this.selectChangerOptions = [];
      this.changerSelection = null;
    },
   
    openChanger(payload) {
      this.selectChangerOptions = payload.agentOptions;
      this.changerConfig = payload.changerConfig;
      this.changerSelection = payload.changerSelection;
      this.previousKey = payload.previousKey;
      this.$bvModal.show(payload.changerUID);
    }
  },
};
</script>

<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>

<style lang="scss">
.bg-default, .b-sidebar-header {
  background-color: #151925 !important;
}

.height-400 {
  height: 400px;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.jv-dark {
  background: #161d31;
  white-space: nowrap;
  color: $white;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;
  box-shadow: 0 2px 7px rgb(0 0 0 / 15%) !important;
  border-color: transparent !important;
  position: relative !important;

  .jv-ellipsis {
    color: $white;
    background-color: #293148;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 5px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #a6e22e;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: $white;
    }
    &.jv-boolean {
      color: #ae81ff;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #ae81ff;
    }
    &.jv-number-float {
      color: #ae81ff;
    }
    &.jv-number-integer {
      color: #ae81ff;
    }
    &.jv-object {
      color: $white;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #e6db74;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #161d31;
        }
      }
    }
  }
}

.h3-separator {
  color: #ffffff2b !important;
  font-size: 40px;
}

.test-terminal {
  max-height: 80vh;
  .submit-button {
    margin-top: 22px;
  }
}

.my-editor {
  background: #161d31;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre, textarea {
    background: none !important;
    border: none !important;
    outline: none !important;

    &:focus, &:active, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
}

.json-input-disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
}
</style>
